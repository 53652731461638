import LogAction from "../Utilities/LogAction";

function ContactPageInfoHours() {
    return <div className="grid-page-2-sub contactpage-info">
            <h3>Odwiedź nas!</h3>
            <p>Warszawa, 02-149 ul. Hynka 2a <br></br>    
            <a target="_blank" href='https://www.google.com/maps/place/Hyundai+Motortest+Anna+Szadkowska+Autoryzowany+Dealer+Marki+Hyundai/@52.1875733,20.9641901,17z/data=!3m1!4b1!4m6!3m5!1s0x4719334701741423:0x9ca8a6375bdb6c4a!8m2!3d52.18757!4d20.966765!16s%2Fg%2F1td0x6ly?entry=ttu'>
                <button onClick={() => LogAction('/tucson wyznacz trasę')} className='actionbutton'>Wyznacz trasę</button>
            </a>
            
            </p>
            <h3>Godziny otwarcia salonu:</h3>
            <p>Od poniedziałku do piątku: 9:00 - 19:00</p>
            <p>Sobota: 9:00 - 15:00</p>
            <h3>Godziny otwarcia serwisu:</h3>
            <p>Od poniedziałku do piątku: 7:30 - 18:30</p>
            <p>Sobota: zamknięte</p>
        </div>
}

export default ContactPageInfoHours;