import React, { useState } from 'react';
import FormStep from './FormStep';
import { Circles } from 'react-loader-spinner';
import LogAction from '../Utilities/LogAction';

function Form({ formData, formDataValues, setFormData, setErrors, setIsSubmitted, setIsLoading, errors, isLoading }) {
    const [step, setStep] = useState(1);
    const stepsData = formData.formFields;

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        // Reset marketing error when marketing is checked
        if (name === 'marketing' && checked) {
            setErrors((prevErrors) => {
                const { marketingConsent, ...rest } = prevErrors;
                return rest;
            });
        }
        
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleFileChange = (e) => {
        const acceptedFormats = ['image/jpeg', 'image/png', 'image/gif'];
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => acceptedFormats.includes(file.type));

        const filePreviews = validFiles.map(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise(resolve => {
                reader.onload = () => {
                    resolve({ name: file.name, src: reader.result });
                };
            });
        });

        Promise.all(filePreviews).then(previews => {
            setFormData(prevState => ({
                ...prevState,
                images: [...prevState.images, ...previews]
            }));
        });
    };

    const removeImage = (index) => {
        setFormData(prevState => ({
            ...prevState,
            images: prevState.images.filter((_, i) => i !== index)
        }));
    };

    const validate = () => {
        const newErrors = {};

        const currentStepFields = stepsData[step - 1]?.step || [];
        currentStepFields.forEach(group => {
            group.group[0].forEach(field => {
                if (field.field[0].required && !formDataValues[field.field[0].id]) {
                    newErrors[field.field[0].id] = `${field.field[0].description} te dane są wymagane.`;
                }
            });
        });

        if (step === stepsData.length) {
            const { marketing, marketing_sms, marketing_phone, marketing_email } = formDataValues;
            if (marketing && !marketing_sms && !marketing_phone && !marketing_email) {
                newErrors.contact = "Wybierz przynajmniej jedną formę kontaktu (marketing sms, marketing telefon lub marketing email).";
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        LogAction('/tucson, form next step');
        if (validate()) {
            setStep(step + 1);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        LogAction('/tucson, form submit');
        if (validate()) {
            setIsLoading(true);
            fetch('https://script.google.com/macros/s/AKfycbxVFq_hi8xeaK2nuFHBw-45G-Kn4rKoLUokRycq5N5LCcPVOKG8w8rJgL-r8MylSNrV/exec', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataValues),
            })
            .then(data => {
                setIsLoading(false);
                setIsSubmitted(true);
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error:', error);
            });
        }
    };

    const steps = stepsData.map((stepData, index) => (
        <FormStep
            key={index}
            stepData={stepData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            removeImage={removeImage}
            formDataValues={formDataValues}
            isActive={index + 1 === step}
            errors={errors}
        />
    ));

    return (
        <div className="form">
            <form onSubmit={handleFormSubmit}>
                {steps[step - 1]}
                {errors.contact && <div className="form-error">{errors.contact}</div>}
                {Object.keys(errors).map(key => (
                    <div key={key} className="form-error">{errors[key]}</div>
                ))}
                <div className="form-navigation">
                    {step > 1 && (
                        <button type="button" onClick={() => setStep(step - 1)}>
                            Wróć
                        </button>
                    )}
                    {step < stepsData.length ? (
                        <button type="button" onClick={handleNextStep}>
                            Dalej
                        </button>
                    ) : (
                        <button type="submit">
                            Wyślij
                        </button>
                    )}
                </div>
            </form>
            {isLoading && (
                <div className="modal">
                    <div className="modal-content">
                        <Circles
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                        <p>Proszę czekać, formularz jest przetwarzany...</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Form;
