import React from 'react';
import TopMenuNavigationMenuItem from "./TopMenuNavigationMenuItem";

function TopMenuNavigationMenu({ isOpen, toggleMenu }) {
    return (
        <nav>
            <ul className={isOpen ? 'topmenunavigation-menu' : 'topmenunavigation-menu hidden'}>
                <TopMenuNavigationMenuItem name='Konfigurator' path='/konfigurator' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Samochody' path='/samochody' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Sprzedaj samochód' path='/odkupy' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Serwis' path='/serwis' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Naprawy blacharskie' path='/lsk' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Ubezpieczenia' path='/ubezpieczenia' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Kariera' path='/kariera' toggleMenu={toggleMenu} />
                <TopMenuNavigationMenuItem name='Kontakt' path='/kontakt' toggleMenu={toggleMenu} />
            </ul>
        </nav>
    );
}

export default TopMenuNavigationMenu;
