import React, { useEffect, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

const CookieConsentComponent = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(getCookieConsentValue("myWebsiteCookieConsent"));

  useEffect(() => {
    if (isConsentGiven === "true") {
      // Inicjalizacja Google Tag Manager
      const gtmScript = document.createElement('script');
      gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-TRQ7KMNT';
      gtmScript.async = true;
      document.head.appendChild(gtmScript);

      // Inicjalizacja Google Analytics
      const gtagScript = document.createElement('script');
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-757LB3RW1J';
      gtagScript.async = true;
      document.head.appendChild(gtagScript);

      gtagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-757LB3RW1J');
      };

      // Inicjalizacja Facebook Pixel
      const fbPixelScript = document.createElement('script');
      fbPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '7840342649342236'); 
        fbq('track', 'PageView');
        fbq('track', 'Contact');
        fbq('track', 'Lead');
      `;
      document.head.appendChild(fbPixelScript);

      const fbPixelNoScript = document.createElement('noscript');
      fbPixelNoScript.innerHTML = `
        <img height="1" width="1" 
        src="https://www.facebook.com/tr?id=7840342649342236&ev=PageView&noscript=1"/>
      `;
      document.body.appendChild(fbPixelNoScript);

    } else {
      // Usuń zewnętrzne ciasteczka, jeśli zgoda nie została wyrażona
      const cookiesToDelete = ["externalCookie", "_ga", "_gid", "_gat", "_ga_757LB3RW1J", "fb_pixel"];
      cookiesToDelete.forEach(cookie => {
        document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      });
    }
  }, [isConsentGiven]);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Zgadzam się"
      declineButtonText="Odrzuć"
      cookieName="myWebsiteCookieConsent"
      buttonClasses="cookie-consent-button"
      declineButtonClasses="cookie-consent-decline-button"
      containerClasses="cookie-consent-banner"
      expires={150}
      onAccept={() => {
        setIsConsentGiven("true");
      }}
      onDecline={() => {
        setIsConsentGiven("false");
        const cookiesToDelete = ["externalCookie", "_ga", "_gid", "_gat", "_ga_757LB3RW1J", "fb_pixel"];
        cookiesToDelete.forEach(cookie => {
          document.cookie = `${cookie}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        });
      }}
    >
      Ta strona używa plików cookie, aby zapewnić najlepszą jakość korzystania z naszej strony.{" "}
      <a href="/polityka-prywatnosci" style={{ color: "#fff" }}>Dowiedz się więcej</a>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
