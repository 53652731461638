import React from 'react';
import FormInput from './FormInput';
import FormHeaderGroup from './FormHeaderGroup';

function FormGroup({ groupData, handleChange, handleFileChange, removeImage, formDataValues }) {
    const inputs = groupData.group[0].map((input, index) => (
        <FormInput 
            key={index} 
            inputData={input} 
            handleChange={handleChange} 
            handleFileChange={handleFileChange}
            removeImage={removeImage}
            formDataValues={formDataValues}
        />
    ));

    // Determine the className based on the groupSize
    const groupSizeClass = `grid-page-${groupData.groupSize}`;

    return (
        <div className={`form-group ${groupSizeClass}`}>
            {groupData.groupName === '' ? '' :<FormHeaderGroup title={groupData.groupName} />}
            {inputs}
        </div>
    );
}

export default FormGroup;
