import React from 'react';
import DesktopLogo from '../../../assets/Motortest_logo_poziome.png';
import MobileLogo from '../../../assets/LogoHyundai.png'; // Import the mobile logo

const TopMenuNavigationLogo = () => {
    return (
        <div className="topmenunavigation-logo">
            <a href='/tucson'>
                <img className="topmenunavigation-logo-img desktop-logo" src={DesktopLogo} alt="Logo" width='200em' />
                <img className="topmenunavigation-logo-img mobile-logo" src={MobileLogo} alt="Logo" />
            </a>
        </div>
    );
};

export default TopMenuNavigationLogo;
