import React, { useState, useEffect, useRef } from 'react';

function Carousel({ data, height, interval = 3000, swipePauseDuration = 10000 }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const startX = useRef(0);
    const endX = useRef(0);
    const intervalRef = useRef(null);
    const timeoutRef = useRef(null);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % data.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + data.length) % data.length);
    };

    const resetInterval = () => {
        clearInterval(intervalRef.current);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            intervalRef.current = setInterval(nextSlide, interval);
        }, swipePauseDuration);
    };

    useEffect(() => {
        intervalRef.current = setInterval(nextSlide, interval);
        return () => {
            clearInterval(intervalRef.current);
            clearTimeout(timeoutRef.current);
        };
    }, [interval]);

    const handleDotClick = (index) => {
        setCurrentSlide(index);
        resetInterval();
    };

    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        endX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (startX.current - endX.current > 50) {
            nextSlide();
        } else if (startX.current - endX.current < -50) {
            prevSlide();
        }
        resetInterval();
    };

    return (
        <div className="carousel" style={{ height }}>
            <div
                className="carousel-track-container"
                style={{ height }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <ul className="carousel-track" style={{ transform: `translateX(-${currentSlide * 100}%)`, height }}>
                    {data.map((slide, index) => (
                        <li className="carousel-slide" key={index} style={{ height }}>
                            <img src={slide.image} alt={`Slide ${index + 1}`} style={{ height: '100%' }} />
                            {slide.text && (
                                <div className={`text-container ${slide.position}`}>
                                    <div className={slide.color}>
                                        <h3>{slide.text}</h3>
                                    </div>
                                    {slide.button && slide.link && (
                                        <div className='button-container'>
                                            <a href={slide.link}><button>{slide.button}</button></a>
                                        </div>
                                    )}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="carousel-nav">
                {data.map((_, index) => (
                    <button
                        key={index}
                        className={`carousel-indicator ${index === currentSlide ? 'current-slide' : ''}`}
                        onClick={() => handleDotClick(index)}
                    ></button>
                ))}
            </div>
        </div>
    );
}

export default Carousel;
