import { useEffect, useState } from "react";

import TopMenuNavigationLogo from "../TopMenuNavigation/TopMenuNavigationLogo";
import TopMenuNavigationContact from "../TopMenuNavigation/TopMenuNavigationContact";
import SectionHeader from "../Neutral/SectionHeader";
import ScrollToTopButton from "../Utilities/ScrollToTopBotton";
import ContactPageInfo from "../ContactPage/ContactPageInfo";
import Form from "../Form/Form";

import { serviceForm } from '../../../data/formsData';
import Salon from '../../../assets/salon.jpg';
import BezpieczneDzieckoImage from '../../../assets/salon.jpg'; // Dodaj obrazek
import AkcjaKlimatyzacjaImage from '../../../assets/model_tucson.png'; // Dodaj obrazek
import ArticleTwoThird from "../Articles/ArticleTwoThird";

const initialFormState = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    vin: '',
    registration_number: '',
    marketing: false,
    marketing_sms: false,
    marketing_phone: false,
    marketing_email: false,
};

const articleOne = {
    title: 'Bezpieczne dziecko',
    text: 'Dzieci są najważniejsze. Dlatego w naszym serwisie oferujemy specjalne foteliki samochodowe dla dzieci w atrakcyjnych cenach. Zadbaj o bezpieczeństwo swojego dziecka podczas jazdy samochodem.',
    img: BezpieczneDzieckoImage,
}

const articleTwo = {
    title: 'Akcja Klimatyzacja',
    text: 'Oferta "Akcja Klimatyzacja" obejmuje oryginalne filtry kabinowe Hyundai tańsze o 15%. Sprawna klimatyzacja zapewnia bezpieczeństwo i komfort podróży. Wymień filtr kabinowy na nowy oryginalny, aby powstrzymać pyłki, drobnoustroje i inne niepożądane czynniki.',
    img: AkcjaKlimatyzacjaImage,
}

function ServicePage() {
    useEffect(() => {
        document.title = "Motortest Serwis Hyundai"
    }, []);

    useEffect(() => {
        const accordions = document.querySelectorAll('.accordion-button');
        accordions.forEach(button => {
            button.addEventListener('click', () => {
                const content = button.nextElementSibling;
                if (content.style.display === 'block') {
                    content.style.display = 'none';
                } else {
                    content.style.display = 'block';
                }
            });
        });
    }, []);
    

    const [formData, setFormData] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validate = () => {
        const newErrors = {};
        const { marketing_phone, marketing_email } = formData;

        if (!marketing_phone && !marketing_email) {
            newErrors.contact = "At least one contact method (marketing phone or marketing email) is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRestart = () => {
        setIsSubmitted(false);
        setFormData(initialFormState);
    };

    if (isSubmitted) {
        return (
            <div className="form-summary">
                <p>Dziękujemy za przesłanie formularza. Nasz Doradca skontaktuje się z Tobą w przeciągu kilku godzin.</p>
                <button onClick={handleRestart}>Wróć do formularza</button>
            </div>
        );
    }

    return (
        <>
            <header className='topmenunavigation animation-container'>
                <TopMenuNavigationLogo />
                <TopMenuNavigationContact />
            </header>
            <div className='grid-page'>
                <div className='grid-page-1 service-hero animation-container'>
                    <img src={Salon} alt="Salon" />
                    <h1>Autoryzowany Serwis Hyundai</h1>
                    <h2>Zdiagnozujemy każdy przypadek</h2>
                </div>
                <SectionHeader title='Umów się na serwis' />
                <div className='grid-page-1 animation-container'>
                    <Form
                        formData={serviceForm}
                        formDataValues={formData}
                        setFormData={setFormData}
                        setErrors={setErrors}
                        setIsSubmitted={setIsSubmitted}
                        setIsLoading={setIsLoading}
                        errors={errors}
                        isLoading={isLoading}
                    />
                </div>

                <SectionHeader title='Poznaj aktualne promocje' />
                <div className="accordion ">
                    <div className="accordion-item">
                        <button className="accordion-button">Bezpieczne dziecko</button>
                
                            <ArticleTwoThird articleData={articleOne} />
                           

                    </div>
                    <div className="accordion-item">
                        <button className="accordion-button">Akcja Klimatyzacja</button>
                        <ArticleTwoThird articleData={articleTwo} />
                    </div>
                    {/* Możesz dodać więcej promocji w podobny sposób */}
                </div>

                <SectionHeader title='Odwiedź nas' />
                <div className='grid-page-1 animation-container grid-color-white'>
                    <ContactPageInfo />
                </div>
            </div>
            <ScrollToTopButton />
        </>
    );
}

export default ServicePage;
