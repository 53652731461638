import React from 'react';
import { brands, models, colors } from '../../../data/enumsData';

function FormEnums({ id }) {
    switch (id) {
        case 'brand':
            return brands.map((brand, index) => (
                <option key={index} value={brand}>
                    {brand}
                </option>
            ));
        case 'model':
            return models.map((model, index) => (
                <option key={index} value={model}>
                    {model}
                </option>
            ));

        case 'color':
            return colors.map((color, index) => (
                <option key={index} value={color}>
                    {color}
                </option>
            ));
        default:
            return [].map((item, index) => (
                <option key={index} value={item}>
                    {item}
                </option>
            ));
    }
}

export default FormEnums;
