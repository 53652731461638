import ContactPageHours from "./ContactPageInfoHours";
import ContactPageMap from "./ContactPageInfoMap";


const ContactPageInfo = () => {

  return (
    <div className="grid-page-2 contactpage-info">
      
      <ContactPageMap />

      <ContactPageHours />

    </div>
  );
}

export default ContactPageInfo;
