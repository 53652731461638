import LogAction from '../../components/Utilities/LogAction';

function ContactPagePhone() {
  return (
    <div className="grid-page-1 contactpage-info">
      <h2>Zadzwoń do nas</h2>
      <h2><a onClick={() => LogAction('/tucson contact phone kliknij telefon')} href="tel:+48224700821">22 470 08 21</a> </h2>
    </div>
  );
}

export default ContactPagePhone;