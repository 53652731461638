import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

import TopMenuNavigationLogo from '../TopMenuNavigation/TopMenuNavigationLogo';
import TopMenuNavigationContact from '../TopMenuNavigation/TopMenuNavigationContact';
import SectionHeader from '../Neutral/SectionHeader';

const MarketingPage = () => {
  const [id, setId] = useState('');
  const [isMarketingConsent, setIsMarketingConsent] = useState(false); // Stan dla pierwszej zgody
  const location = useLocation();
  const navigate = useNavigate(); // Hook do nawigacji

  useEffect(() => {
    // Pobierz id z URL
    const params = new URLSearchParams(location.search);
    const clientId = params.get('id');
    setId(clientId || ''); // Ustaw id, jeśli istnieje, lub pusty string
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formData = {
      id: event.target.id.value,
      zgodaMarketingowa: event.target.zgodaMarketingowa.value,
      kontaktEmail: event.target.kontaktEmail.value,
      kontaktTelefon: event.target.kontaktTelefon.value,
      userAgent: navigator.userAgent, // Dane urządzenia
    };
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxGODCNLIKAlm2mFjR8srl6O7yp3WSxBbmXV1JRmRAN1NvISRe5rvq9vs4u-zXDgU0TjQ/exec', {
        method: 'POST',
        body: JSON.stringify(formData),
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      alert('Formularz został pomyślnie zapisany!');
      
      // Przekierowanie po 3 sekundach
      setTimeout(() => {
        navigate('/another-page'); // Zmieniamy na ścieżkę, na którą chcesz przekierować
      }, 3000); // 3000ms to 3 sekundy

    } catch (error) {
      console.error('Error:', error);
      alert('Formularz został pomyślnie zapisany!');
    }
  };

  // Funkcja obsługująca zmianę pierwszej zgody
  const handleMarketingConsentChange = (event) => {
    const isConsentGiven = event.target.value === "Tak";
    setIsMarketingConsent(isConsentGiven); // Zaktualizuj stan
  };

  return (
    <>
      <header className='topmenunavigation animation-container'>
          <TopMenuNavigationLogo />
          <TopMenuNavigationContact />
      </header>
      <form className='marketing-page-form' onSubmit={handleSubmit}>
        <h1>Zgoda marketingowa</h1>
        <p>Zdecyduj czy chcesz otrzymać dostęp do limitowanych okazji, najlepszych promocji!</p>
      
        <div className='marketing-page-form-box'>
          <label>
              Wyrażam zgodę na przetwarzanie moich danych osobowych przez Motortest Spółka z ograniczoną odpowiedzialnością - dawniej Motortest Anna Szadkowska - z siedzibą w Warszawie, przy ul. Franciszka Hynka 2A, oraz przez Hyundai Motor Poland Sp. z o.o. z siedzibą w Warszawie, przy ul. Wołoska 24, w zakresie Imię, Nazwisko, dane teleadresowe, adres email do celów marketingowych. Oświadczam iż moja zgoda jest dobrowolna i może być w każdej chwili wycofana.
          </label>
          <div>
            <input
              type="radio"
              name="zgodaMarketingowa"
              value="Tak"
              required
              onChange={handleMarketingConsentChange}
            /> Tak
            <input
              type="radio"
              name="zgodaMarketingowa"
              value="Nie"
              onChange={handleMarketingConsentChange}
            /> Nie
          </div>
        </div>

        <p>Jeżeli zaznaczyłeś tak w pierwszym punkcie, wybierz jakimi metodami mamy się z Tobą komunikować</p>

        {/* Email zgoda */}
        <div className={isMarketingConsent ? 'marketing-page-form-box marketing-page-box2 form-input-radio-field' : 'marketing-page-form-box marketing-page-box2 form-input-radio-field form-input-radio-field-disabled'}>
          <b>Email:</b><br></br>
          <label>
              Wyrażam zgodę na otrzymywanie na wskazany przeze mnie adres email informacji handlowych dotyczących promocji oraz produktów i usług oferowanych przez Motortest Spółka z ograniczoną odpowiedzialnością - dawniej Motortest Anna Szadkowska -  w Warszawie, przy ul. Franciszka Hynka 2a w rozumieniu ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.). Oświadczam iż moja zgoda jest dobrowolna i może być w każdej chwili wycofana.
          </label>
          <div>
            <input
              type="radio"
              name="kontaktEmail"
              value="Tak"
              required
              disabled={!isMarketingConsent} // Wyłącz, jeśli nie zaznaczono pierwszej zgody
            /> Tak
            <input
              type="radio"
              name="kontaktEmail"
              value="Nie"
              required
              disabled={!isMarketingConsent} // Wyłącz, jeśli nie zaznaczono pierwszej zgody
            /> Nie
          </div>
        </div>

        {/* Telefon zgoda */}
        <div className={isMarketingConsent ? 'marketing-page-form-box marketing-page-box2 form-input-radio-field' : 'marketing-page-form-box marketing-page-box2 form-input-radio-field form-input-radio-field-disabled'}>
          <b>Telefon:</b><br></br>
          <label>
              Wyrażam zgodę na przekazywanie przez Motortest Spółka z ograniczoną odpowiedzialnością - dawniej Motortest Anna Szadkowska - z siedzibą w Warszawie, przy ul. Franciszka Hynka 2a oraz przez Hyundai Motor Poland z siedzibą w Warszawie, przy ul. Wołoska 24, treści dotyczących oferty handlowej na podany przeze mnie numer telefonu, w tym przy użyciu automatycznych systemów wywołujących w rozumieniu ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz. U z 2014 r. poz. 243 ze zm.) Oświadczam, że moja zgoda jest dobrowolna i może być w każdej chwili wycofana.
          </label>
          <div>
            <input
              className='form-input-radio'
              type="radio"
              name="kontaktTelefon"
              value="Tak"
              required
              disabled={!isMarketingConsent} // Wyłącz, jeśli nie zaznaczono pierwszej zgody
            /> Tak
            <input
              type="radio"
              name="kontaktTelefon"
              value="Nie"
              disabled={!isMarketingConsent} // Wyłącz, jeśli nie zaznaczono pierwszej zgody
            /> Nie
          </div>
        </div>

        {/* Ukryte pole identyfikatora */}
        <input type="hidden" name="id" value={id} />

        <div>
          <button type="submit">Wyślij formularz</button>
        </div>
      </form>
    </>
  );
};

export default MarketingPage;
