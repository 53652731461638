import '../../../app_styles/css/components/ContactPage.scss'

import { useEffect } from "react";

import SectionHeader from "../Neutral/SectionHeader";
import ContactPageInfo from "./ContactPageInfo";
import ContactPageForm from "./ContactPageForm";
import ContactPageTeam from "./ContactPageTeam";    

function ContactPage() {

        useEffect(() => {
            document.title = "Motortest kontakt"
         }, []);
        

    return <div className="grid-page">
        <SectionHeader title='Kontakt'/>
        <ContactPageInfo />
        <SectionHeader title='Zadaj nam pytanie'/>
        <ContactPageForm />
        <SectionHeader title='Nasz zespół'/>
        <ContactPageTeam /> 
    </div>
}

export default ContactPage;