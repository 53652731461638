import React from 'react';
import FormGroup from './FormGroup';
import FormHeader from './FormHeader';

function FormStep({ stepData, handleChange, handleFileChange, removeImage, formDataValues, isActive }) {
    const groupData = stepData.step;

    const groups = groupData.map((group, index) => (
        <FormGroup 
            key={index} 
            groupData={group} 
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            removeImage={removeImage}
            formDataValues={formDataValues} 
        />
    ));

    return (
        <div className={`form-step ${isActive ? 'active' : ''}`}>
            {stepData.stepName === null ? '' : <FormHeader title={stepData.stepName} />}
            {groups}
        </div>
    );
}

export default FormStep;
