// File: components/ModelsPage/ColorConfigurator.js

import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import LogAction from '../../components/Utilities/LogAction';

// Helper function to import all filenames from a directory
const importAllFilenames = (r) => {
  return r.keys().map((item) => item.replace('./', ''));
};

// Importing color filenames and corresponding 360 filenames
const allColorNames = importAllFilenames(require.context('../../../assets/kolorniki/TucsonFL/Kolory', false, /\.(png|jpe?g|svg)$/));
const allTucsonNames = importAllFilenames(require.context('../../../assets/kolorniki/TucsonFL', false, /\.(png|jpe?g|svg)$/));

// Extracting only the first 3 letters of color filenames
const colorOptions = allColorNames.map(name => {
  const colorCode = name.substring(0, 3);
  const colorNameParts = name.substring(3, name.length - 4).split('_');
  const colorName = colorNameParts.join(' ');
  const colorFinish = colorNameParts[colorNameParts.length - 1];
  const formattedColorName = `(${colorCode}) ${colorName.replace(colorFinish, '')} ${colorFinish === 'Pearl' ? 'perłowy' : colorFinish === 'Metallic' ? 'metalizowany' : colorFinish === 'Matt' ? 'matowy' : ''}`.trim();
  const images = allTucsonNames
    .filter(imageName => imageName.includes(`EXT_${colorCode}_`))
    .sort() // Sort images by filename
    .map(imageName => ({
      src: require(`../../../assets/kolorniki/TucsonFL/${imageName}`),
      filename: imageName
    }));
  return {
    code: colorCode,
    name: formattedColorName,
    thumbnail: require(`../../../assets/kolorniki/TucsonFL/Kolory/${name}`),
    images: images
  };
});

const ColorConfigurator = () => {
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleImageLoad = () => setLoading(false);

    const img = new Image();
    img.src = selectedColor.images[currentImageIndex].src;
    img.onload = handleImageLoad;

    setLoading(true);

    return () => {
      img.onload = null; // Cleanup
    };
  }, [selectedColor, currentImageIndex]);

  const handleColorChange = (color) => {
    setSelectedColor(color);
    LogAction('/tucson, color change ' + color.name);
    setCurrentImageIndex(0);
    setLoading(true);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? selectedColor.images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === selectedColor.images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleSwipe = (deltaX) => {
    const imageCount = selectedColor.images.length;
    const swipeThreshold = 150; // Threshold in pixels for one swipe
    let newIndex = currentImageIndex - Math.round(deltaX / swipeThreshold);

    if (newIndex < 0) {
      newIndex = (newIndex % imageCount + imageCount) % imageCount;
    } else if (newIndex >= imageCount) {
      newIndex = newIndex % imageCount;
    }

    setCurrentImageIndex(newIndex);
  };

  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => handleSwipe(eventData.deltaX),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="colorconfigurator">
      <div className="colorconfigurator-main">
        <div className="colorconfigurator-image-container">
          {loading && <div className="loading-indicator">Ładowanie...</div>}
          <img 
            src={selectedColor.images[currentImageIndex].src} 
            alt={`Tucson ${selectedColor.name}`} 
            style={{ display: loading ? 'none' : 'block' }}
          />
        </div>
      </div>
      <div className="colorconfigurator-swipe-area" {...swipeHandlers}>
        Przeciągnij tutaj, aby obrócić obraz
      </div>
      <div className="colorconfigurator-color-name">
        {selectedColor.name}
      </div>
      <div className="colorconfigurator-color-options">
        {colorOptions.map((color) => (
          <button 
            key={color.code} 
            onClick={() => handleColorChange(color)}
            className={selectedColor.code === color.code ? 'selected' : ''}
          >
            <img 
              src={color.thumbnail} 
              alt={color.name} 
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ColorConfigurator;
