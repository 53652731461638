import React from 'react';
import FormImageUpload from './FormImageUpload';
import FormEnums from './FormEnums';

function FormInput({ inputData, handleChange, handleFileChange, removeImage, formDataValues, errors }) {
    const field = inputData?.field?.[0];
    const value = formDataValues[field?.id] || '';

    if (!field) return null;

    const { 
        htmlFor, description, type, id, placeholder, pattern, required, minLength, maxLength, elementSize, options
    } = field;

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = (pattern && pattern === "^[A-Z]+$") ? value.toUpperCase() : value;
        handleChange({ target: { name, value: newValue, type, checked } });
    };

    const renderInputField = () => {
        const elementSizeClass = `grid-page-sub-${elementSize}`;
        const isMarketingConsent = formDataValues.marketing;

        switch (type) {
            case 'textarea':
                return (
                    <div className={`form-input-textarea ${elementSizeClass}`}>
                        <label className="form-input-textarea-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <textarea
                            className="form-input-textarea-field"
                            id={id}
                            name={id}
                            value={value}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                            required={required || false}
                        />
                    </div>
                );
            case 'text':
            case 'number':
                return (
                    <div className={`form-input-text ${elementSizeClass}`}>
                        <label className="form-input-text-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <input
                            className="form-input-text-field"
                            type={type}
                            id={id}
                            name={id}
                            value={value}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                            required={required || false}
                            minLength={minLength}
                            maxLength={maxLength}
                            pattern={pattern || undefined}
                        />
                    </div>
                );
            case 'image':
                return (
                    <div className={`form-input-text ${elementSizeClass}`}>
                        <label className="form-input-text-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <FormImageUpload 
                            handleFileChange={handleFileChange} 
                            removeImage={removeImage} 
                            images={value}
                        />
                    </div>
                );
            case 'checkbox':
                if (id === 'marketing_sms' || id === 'marketing_phone' || id === 'marketing_email') {
                    return (
                        <div className={`form-input-checkbox ${elementSizeClass}`}>
                            <input
                                className="form-input-checkbox-field"
                                type={type}
                                id={id}
                                name={id}
                                checked={value}
                                onChange={handleInputChange}
                                required={required || false}
                                disabled={!isMarketingConsent}
                            />
                            <label className="form-input-checkbox-label" htmlFor={htmlFor}>
                                {description}:
                            </label>
                            {!isMarketingConsent && (
                                <div className="form-error">By wybrać tę opcję, musisz wyrazić zgodę marketingową</div>
                            )}
                        </div>
                    );
                }
                return (
                    <div className={`form-input-checkbox ${elementSizeClass}`}>
                        <input
                            className="form-input-checkbox-field"
                            type={type}
                            id={id}
                            name={id}
                            checked={value}
                            onChange={handleInputChange}
                            required={required || false}
                        />
                        <label className="form-input-checkbox-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                    </div>
                );
            case 'enum':
                return (
                    <div className={`form-input-enum ${elementSizeClass}`}>
                        <label className="form-input-enum-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <select
                            className="form-input-enum-field"
                            id={id}
                            name={id}
                            value={value}
                            onChange={handleInputChange}
                            required={required || false}
                        >
                            <option value="" disabled>{placeholder}</option>
                            <FormEnums id={id} />
                        </select>
                    </div>
                );
            case 'radio':
                return (
                    <div className={`form-input-radio ${elementSizeClass}`}>
                        <label className="form-input-radio-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <div className="form-input-radio-field">
                            {options && options.map((option, index) => (
                                <div key={index} className="form-input-radio-field-option">
                                    <input 
                                        type="radio"
                                        id={`${id}_${index}`}
                                        name={id}
                                        value={option.value}
                                        checked={value === option.value}
                                        onChange={handleInputChange}
                                        required={required || false}
                                    />
                                    <label htmlFor={`${id}_${index}`}>{option.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={`form-input-text ${elementSizeClass}`}>
                        <label className="form-input-text-label" htmlFor={htmlFor}>
                            {description}:
                        </label>
                        <input
                            className="form-input-text-input"
                            type={type}
                            id={id}
                            name={id}
                            value={value}
                            placeholder={placeholder}
                            onChange={handleInputChange}
                            required={required || false}
                            minLength={minLength}
                            maxLength={maxLength}
                            pattern={pattern || undefined}
                        />
                    </div>
                );
        }
    };

    return (
        <>
            {renderInputField()}
        </>
    );
}

export default FormInput;
