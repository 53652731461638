async function LogAction(action) {
  const url = 'https://script.google.com/macros/s/AKfycbwtB5T1xPhr2nKokLX4OHAvJhMzg2tWf7AfJ7_9-PPy3CDdmWRZWFEzyN0Z8MzUhYugdg/exec'; // wklej tutaj URL WebApp

  try {
    // Pobierz adres IP użytkownika
    const ipResponse = await fetch('https://api.ipify.org?format=json');
    if (!ipResponse.ok) throw new Error('Failed to fetch IP address');
    const ipData = await ipResponse.json();

    // Pobierz położenie geograficzne użytkownika na podstawie adresu IP
    let locationData = {};
    try {
      const locationResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
      if (locationResponse.ok) {
        locationData = await locationResponse.json();
      } else {
        console.warn('Failed to fetch location data. Proceeding with minimal info.');
      }
    } catch (err) {
      console.warn('Error while fetching location data:', err.message);
    }

    // Pobierz informacje o przeglądarce i urządzeniu użytkownika
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const userAgent = navigator.userAgent;

    // Pobierz referrer
    const referrer = document.referrer;

    // Zbuduj dane do wysłania
    const data = {
      timestamp: new Date().toISOString(),
      action: action,
      ip: ipData.ip || 'Unknown',
      location: {
        city: locationData.city || 'Unknown',
        region: locationData.region || 'Unknown',
        country: locationData.country_name || 'Unknown',
        latitude: locationData.latitude || null,
        longitude: locationData.longitude || null,
      },
      screenResolution: screenResolution,
      userAgent: userAgent,
      referrer: referrer || 'Direct',
    };

    // Wyślij dane
    const response = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      console.error('Failed to send data to server');
    } else {
      console.log('Data sent successfully');
    }
  } catch (error) {
    console.error('Error in LogAction function:', error.message);
  }
}

export default LogAction;
