

const ArticleTwoThird = ({ articleData }) => {
  return (
    <div className="grid-page-2-3 article-two-third">
        <div className="grid-page-2-3-sub">
            <h3>{articleData.title}</h3>
            <p>{articleData.text}</p>
        </div>
        <div className="grid-page-2-3-sub article-content">
            <img src={articleData.img == null ? 'https://placehold.co/600x400' : articleData.img} alt={articleData.imgAlt} />
        </div>
    </div>
  );
};   

export default ArticleTwoThird;
