function Footer() {
    return (
        <footer className="grid">
            <div className="footer-layout">
                <div className="grid-page-sub-1">
                    <h4>Mapa strony</h4>
                    <ul>
                        <li>Konfigurator</li>
                        <li>Konfigurator</li>
                        <li>Konfigurator</li>
                        <li>Konfigurator</li>
                        <li>Konfigurator</li>
                        <li>Konfigurator</li>
                    </ul>
                </div>
                <div className="grid-page-sub-1">
                    <h4>Samochody</h4>
                    <ul>
                        <li>i10</li>
                        <li>i20</li>
                        <li>i30</li>
                        <li>i30Cw</li>
                        <li>Tucson</li>
                        <li>Santa Fe</li>
                        <li>Elantra</li>

                    </ul>
                </div>
                <div className="grid-page-sub-1">
                    <h4>Istotne strony</h4>
                    <ul>
                        <li><a href='/polityka-prywatnosci' >Polityka prywatności</a></li>
                        <li>Regulamin</li>
                        <li>Polityka cookies</li>
                        
                    </ul>
                </div>
            </div> 
            
        </footer>
    )
}   

export default Footer;