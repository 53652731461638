/* eslint-disable react/prop-types */

import React from 'react';

function FormImageUpload({ handleFileChange, removeImage, images }) {
    return (
        <div className="upload-container">
            {images.length > 0 && images.map((file, index) => (
                <div key={index} className="preview">
                    <button className="remove-icon" onClick={() => removeImage(index)}>X</button>
                    <img src={file.src} alt={file.name} />
                    <p>{file.name}</p>
                </div>
            ))}
            <label className="upload-placeholder">
                <input type="file" multiple onChange={handleFileChange} />
            </label>
        </div>
    );
}

export default FormImageUpload;
